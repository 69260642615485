import React from 'react';
import { Fade } from "react-awesome-reveal";

const TimelineData = [
    {
        id: "1",
        date: "Step 1",
        title: "We schedule a call",
        description: "The first step of our process is to determine your analytics needs and the paint points in your business. Together, we will design a plan fit for your unique business and marketing needs.",
        image: "timeline-01",
        workingStep: [
            {
                stepTitle: "Identify KPI's",
                stepDescription: "The data that matters to you.",
            },
            {
                stepTitle: "Identify Pain Points",
                stepDescription: "The things you want to improve.",
            },
            {
                stepTitle: "Define Tracking Strategy",
                stepDescription: "Our blueprint for success.",
            },
        ]
    },
    {
        id: "2",
        date: "Step 2",
        title: "We implement your vision",
        description: "Our team will get to work on your unique analytics plan. We will set up constant status updates along the way to ensure you stay up to date with all the progress.",
        image: "timeline-02",
        workingStep: [
            {
                stepTitle: "Cutting edge tools",
                stepDescription: "We can implement a wide array of tools to help you get the insights you need. From server side tracking, to ML augmented reports.",
            },
            {
                stepTitle: "Stress-free Project Management",
                stepDescription: "Managing a project can be hard, that's why we will keep you informed with status updates and clear documentation.",
            },
        ]
    },
    {
        id: "3",
        date: "Step 3+",
        title: "Ready to analyze!",
        description: "Once the implementation project is done, we will meet again to go over all your new analytics tools and reports. We will also provide a set of reports so you can start understanding your customer right away.",
        image: "timeline-03",
        workingStep: [
            {
                stepTitle: "Overview Reports",
                stepDescription: "Depending on your selected package, you will receive a set of daily, weekly, and YoY reports for you to explore your data.",
            },
            {
                stepTitle: "Continuous Support",
                stepDescription: "We know analytics implementations are never truly complete, that's why we provide flexible support options and maintenance packagesfor your future business needs.",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <Fade top>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </Fade>

                                        <Fade top>
                                            <h2 className="title">{data.title}</h2>
                                        </Fade>

                                        <Fade top>
                                            <p className="description">{data.description}</p>
                                        </Fade>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <Fade top>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </Fade>

                                                        <Fade top>
                                                            <p>{data.stepDescription}</p>
                                                        </Fade>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
