import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Google Analytics 4 Implementations',
        description: 'Take your data to the new age of analytics with GA4. Accurately track and measure website engagement and traffic to better optimize your marketing efforts.'
    },
    {
        icon: <FiCast />,
        title: 'Google Analytics 4 Migrations',
        description: "Make sure you don't lose valuable data and boost your analytics with GA4's new suite of features."
    },
    {
        icon: <FiMap />,
        title: 'Custom ML Solutions',
        description: 'Take your analytics to the next level by taking full advantage of Machine Learning analytics solutions such as Sentiment Analysis, Linear Regressions and more.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <Fade top>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </Fade>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;