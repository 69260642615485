import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "1800Flowers Inc.",
        description: "1-800-Flowers is a leading online retailer and franchisor of floral and gourmet food gifts based in the United States. The company was founded in 1976 and has since grown to become one of the largest flower delivery companies in the world.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-01"
    },
    {
        form: "Harry & David",
        description: "Harry & David is a well-established company with a significant presence in the gourmet food and gift industry. They operate multiple retail stores across the US and have a robust online presence, allowing customers to shop from anywhere.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-02"
    },
    {
        form: "Personalization Mall",
        description: "Personalization Mall is a large online retailer of personalized gifts and custom products. With over 20 years of experience, the company has become a trusted source for high-quality, unique gifts that can be customized to suit any occasion.",
        name: "E-Commerce",
        subtitle: "GTM GA4 Migration",
        image: "testimonial-dark-03"
    },
    {
        form: "The Popcorn Factory",
        description: "The Popcorn Factory is a popular company specializing in gourmet popcorn and other confections. They offer a wide array of popcorn flavors, tins, and gift baskets for various occasions, making them a go-to choice for many looking for unique and delicious gifts. They are known for their commitment to quality and use only the finest ingredients in their products.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-04"
    },
    {
        form: "Shari's Berries",
        description: "Shari's Berries is a well-known company that offers gourmet dipped strawberries, chocolate-covered treats, and other delicious confections. They are particularly popular during holidays and special occasions, and their products are known for their exquisite taste and beautiful presentation.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-05"
    },
    {
        form: "Cheryl's Cookies",
        description: "Cheryl's Cookies is a beloved cookie company that offers a wide range of freshly baked cookies, brownies, and other baked goods. They are known for their signature buttercream-frosted cookies, available in various flavors and designs for holidays and special events.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-06"
    },
    {
        form: "Simply Chocolate",
        description: "Simply Chocolate is a premium chocolate retailer that offers a curated selection of chocolates from renowned chocolatiers and local artisans. They provide a wide range of products, including chocolate bars, truffles, and gift boxes, making it easy for customers to find the perfect gift for any occasion.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-07"
    },
    {
        form: "Plants.com",
        description: "Plants.com is an online retailer specializing in live plants, offering everything from flowers and shrubs to houseplants and succulents. They aim to make plant ownership more accessible and enjoyable, providing customers with detailed care instructions and expert advice to help their plants thrive.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Implementation, Custom Reporting",
        image: "testimonial-dark-08"
        },
        {
        form: "Wolferman's Bakery",
        description: "Wolferman's Bakery is a company known for its gourmet baked goods, particularly their signature English muffins. They offer a wide selection of bread, pastries, and gift baskets that make for delightful presents or indulgent treats.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-09"
        },
        {
        form: "Stockyards",
        description: "Stockyards is a company specializing in premium meats, offering a selection of high-quality beef, pork, and poultry products. They are committed to sourcing the finest cuts of meat from trusted suppliers and delivering them to customers with exceptional service and attention to detail.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-10"
        },
        {
        form: "Vital Choice",
        description: "Vital Choice is a health-conscious company that offers sustainably sourced seafood and organic products. They are committed to providing customers with high-quality, nutritious options, including wild-caught fish, shellfish, and supplements.",
        name: "E-Commerce",
        subtitle: "Tealium GA4 Migration, Technical Support and Auditing, Custom Reporting",
        image: "testimonial-dark-11"
        },
        {
        form: "Napco",
        description: "Napco is a leading provider of floral, garden, and gift products, offering an extensive selection of containers, supplies, and decorative items for the floral and gift industries. They are known for their high-quality products and exceptional customer service.",
        name: "E-Commerce",
        subtitle: "GTM GA4 Migration",
        image: "testimonial-dark-12"
        }
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















